import React, { useEffect } from "react";
import { SubLayout, Contact } from "../../components";
import Seo from "../../components/Seo";
// import Seo from "../components/seo";

const ContactPage = () => {
  useEffect(() => {});
  return (
    <SubLayout>
      <Seo
        title="お問い合わせ"
        description="クリニックへのお仕事やご要望、または取材などについてはこちらからお問合せください。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,お問い合わせ,取材,コンタクト,CONTACT"
      />
      <Contact />
    </SubLayout>
  );
};

export default ContactPage;
